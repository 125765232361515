import axios from 'axios';   

const API_TOKEN = process.env.REACT_APP_API_TOKEN; 
const SERVER_URL    = process.env.REACT_APP_SERVER_URL; 

export async function getApiHeader() {
    const header    =   await {
                            'Authorization': 'Bearer '+sessionStorage.getItem('token'),
                            'api-token': API_TOKEN,
                            'NBN-Type': 'NBNTSMYA'
                            };
    
    return header;
}

export function getApiHeaderGuest() {
    const header    =   { 'api-token': API_TOKEN  };
    
    return header;
}
 

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}
 
// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}
 
// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}
 
// set the token and user from the session storage
export const setUserSession = async (token, user) => {
  await sessionStorage.setItem('token', token);
  await sessionStorage.setItem('user', JSON.stringify(user));
}

// export async function getActivationAttemptData() {
//   	const headers    	=   { 'api-token': API_TOKEN  };   
// 	const post_data 	= 	{ session_id: sessionStorage.getItem('activation_session_id')};
// 	let res 			= 	await axios.post(`${ SERVER_URL }activation/attempt-details`,post_data, { headers });
// 	let data 			= res.data.data;  
//   	return data;
// }

export async function getRegisterAttemptData() {
    const headers    	=   { 'api-token': API_TOKEN  };   
    const post_data 	= 	{ reg_session_id: sessionStorage.getItem('register_session_id')};
    let res 			    = 	await axios.post(`${ SERVER_URL }register/attempt-details`,post_data, { headers });
    let data 			    = res.data.data;  
    console.log('Register attempt data - ');
    console.log(data);

    return data;
}


export async function getGuestRechargeAttemptDetails() {
    const headers       =   { 'api-token': API_TOKEN  };   
    const post_data     =   { session_id: sessionStorage.getItem('guest_recharge_session_id')};
    let res             =   await axios.post(`${ SERVER_URL }guest-recharge-attempt-details`,post_data, { headers });
    let data            = res.data.data;  
    return data;
}



export async function getActivationInactivity() {
    const headers       =   { 'api-token': API_TOKEN  };   
    const post_data     =   { session_id: sessionStorage.getItem('activation_session_id')};
    let res             =   await axios.post(`${ SERVER_URL }activation/attempt-inactive`,post_data, { headers });
    let data            = res.data.data;  
    return data;
}

// new optimized functions

export async function getActivationAttemptDataCreateAccount() {
  const headers = { 'api-token': API_TOKEN };
  const post_data = { session_id: sessionStorage.getItem('activation_session_id') };
  let res = await axios.post(`${SERVER_URL}activation/at-dt-ct-ac`, post_data, { headers });
  let data = res.data.data;
  return data;
}


export async function getActivationAttemptDataYourSim() {
  const headers = { 'api-token': API_TOKEN };
  const post_data = { session_id: sessionStorage.getItem('activation_session_id') };
  let res = await axios.post(`${SERVER_URL}activation/at-dt-ur-sm`, post_data, { headers });
  let data = res.data.data;
  return data;
}

export async function getActivationAttemptDataYourMobile() {
  const headers = { 'api-token': API_TOKEN };
  const post_data = { session_id: sessionStorage.getItem('activation_session_id') };
  let res = await axios.post(`${SERVER_URL}activation/at-dt-ur-mb`, post_data, { headers });
  let data = res.data.data;
  return data;
}



export async function getActivationAttemptDataForSetupProfile() {
  const headers = { 'api-token': API_TOKEN };
  const post_data = { session_id: sessionStorage.getItem('activation_session_id') };
  let res = await axios.post(`${SERVER_URL}activation/at-dt-st-pr`, post_data, { headers });
  let data = res.data.data;
  return data;
}

export async function getActivationAttemptDataForIDValidation() {
  const headers = { 'api-token': API_TOKEN };
  const post_data = { session_id: sessionStorage.getItem('activation_session_id') };
  let res = await axios.post(`${SERVER_URL}activation/at-dt-id-vd`, post_data, { headers });
  let data = res.data.data;
  return data;
}

export async function getActivationAttemptDataSummary() {
  const headers = { 'api-token': API_TOKEN };
  const post_data = { session_id: sessionStorage.getItem('activation_session_id') };
  let res = await axios.post(`${SERVER_URL}activation/at-dt-sm`, post_data, { headers });
  let data = res.data.data;
  return data;
}





