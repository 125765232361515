import React, { Component } from 'react';
import axios from 'axios';
import {getApiHeader} from './../../../utils/CommonFunctions';
import swal from '@sweetalert/with-react';
import ErrorMsg from '../errorMsg';
import {withRouter} from "react-router-dom";
import PageLoader from './../../Loader/loader';

const SERVER_URL 	= process.env.REACT_APP_SERVER_URL;

class Plan extends Component {

    constructor(props) {
        super(props);
        let gridClass = (this.props.from === 'activation') ? 'col-lg-6' : 'col-lg-4';
        this.state = {
            plan:[],
            loading:false,
            gridClass:gridClass,
            planSelected:false,
            iddSelected:false,
            plan_reference_number:null,
            plan_after_confirmation:null,
            btn_disable:{'type0': false, 'type1':false, 'type2':false, 'type3':false},
            recharge_session:null,
            showBonus:0,
            isCredit:0,
            selectedPlan:null
        };

        // set & get session for recharge attempt 22-04-22
        const rSession = sessionStorage.getItem('rechargeSession');
        if(rSession==null) {
            const rSession = new Date().getTime();
            sessionStorage.setItem('rechargeSession', rSession);
        }
        if((this.props.from) && (this.props.from === 'activation')) {
            const isCreditSession = sessionStorage.getItem('isCredit');
            if(isCreditSession==null)
            {
                this.state.isCredit = 0;
                //console.log("credit false");
            } else {
                this.state.isCredit = isCreditSession;
                //console.log("credit true");
            }
        } else {
            this.state.isCredit = 0;
        }
    }

    componentDidMount = () => {
        // 10-05-22  activation sim type check
        if((this.props.from === 'activation') || (this.props.from === 'add-mobile-plan')){        
            if(sessionStorage.getItem("showBonus")){
            this.setState({showBonus:sessionStorage.getItem("showBonus")});
            }
        }
        // 10-05-22
        if(this.props.referenceNumberApplied === true) {
            let plans  = [this.props.plan]
            //console.log(plans);
            this.setState({plan:plans, gridClass:'col-lg-6 plan-center', plan_reference_number:this.props.plan_reference_number});
            if(sessionStorage.getItem("showBonus")){
            this.setState({showBonus:sessionStorage.getItem("showBonus")});
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(this.props.selectedPlan !== nextProps.selectedPlan) {
            if(nextProps.type === 'idd') {
                this.setState({iddSelected:nextProps.selectedPlan});
            }
            if(nextProps.type === 'plan') {
                this.setState({planSelected:nextProps.selectedPlan});
            }
        }
        if (this.props.plan !== nextProps.plan) {
            if(nextProps.plan !== null) {
                this.setState({plan:nextProps.plan});
            }
        }
    }

    rechargeConfirm = async (plan) => {

        // set & get session for recharge attempt 22-04-22
        const rSession = sessionStorage.getItem('rechargeSession');
        if(rSession == null) {
            const rSession = new Date().getTime();
            sessionStorage.setItem('rechargeSession', rSession);
        }
        this.state.recharge_session = rSession;

        // set & get session for recharge attempt 22-04-22
        // let obj = {btn_disable:{['type'+plan.is_bolt]: true}}

        this.setState({btn_disable:{['type'+plan.is_bolt]: true}});
        let plan_id = plan.plan_id;
        this.setState({loading:true});
        let headers = await getApiHeader();
        let params = {
            'payment_method':'Card', 
            'plan_id': plan_id, 
            'mobile_number': this.props.mobileNumber, 
            recharge_session : this.state.recharge_session
        }
        let response = null;
        try {
            if(this.props.from == 'recharge-a-friend') {
                let param = {
                    ...params,
                    activation_id: this.props.activation_id
                }
                response = await axios.post(`${ SERVER_URL }recharge/friend`, param, { headers });
            } else {
                console.log(params);
                response = await axios.post(`${ SERVER_URL }recharge/own-number`, params, { headers });
            }
            //console.log('own-number ==>', response);
            this.setState({loading:false});
            swal({
                text: response.data.message,
                icon: "success"})
            .then(() => {
                this.props.history.push("/home");
            });
        } catch(err) {
            //console.log('own-number error==>', err);
            this.setState({loading:false});
            if(err) {
                let errorMessage = '';
                if(err.response && err.response.data && err.response.data.message) {
                    //console.log('own-number error==>', err.response);
                    if(typeof(err.response.data.message) === 'object') {
                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
                    } else {
                        errorMessage = err.response.data.message;
                        if(err.response.data.status == 422){
                            swal({
                                title: 'Recharge Error',
                                text: errorMessage,
                                icon: "error"
                            }).then(()=> {
                                this.props.history.push("/MyPayments");
                            });
                        }else{
                            swal(errorMessage,"error");
                            swal({
                                title: errorMessage,
                                icon: "warning"
                            }).then(()=>{
                                this.props.history.push("/MyPayments");
                            });
                        }
                        
                    }
                } else {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
            }
        }
    }

    recharge = async (plan) => {
        let plan_id = plan.plan_id;
        if((this.props.from === 'activation') || (this.props.from === 'add-mobile-plan')) {
            this.selectPlan(plan);
        } else {
            //===============new================//
            if(this.props.mobileNumber != null && this.props.activeStatus=='1'){
                var balanceMsg = "";
                if(this.props.from == 'recharge' || this.props.from == 'recharge-a-friend') {
                    this.setState({loading:true});
                    let headers = await getApiHeader();
                    let params = {
                        plan_id: plan_id, 
                        mobile_number: this.props.mobileNumber, 
                        recharge_session : this.state.recharge_session,
                        recharge_from : this.props.from
                    }
                    
                    await axios.post(`${SERVER_URL}recharge-popup/data-bank-balance`, params, { headers })
                    .then((response) => {
                        console.log('recharge-popup ==>', response)
                        if(response !== undefined){
                            if(response.status == 200) {
                                if(response.data !== undefined && response.data != null && response.data != ''){
                                    balanceMsg = response.data.message;
                                }else{
                                    balanceMsg = "";
                                }
                            }  
                        }
                        this.setState({ loading: false});
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        //console.log('error ==>', error.response)
                        balanceMsg = "";
                    });
                }


            if(this.props.from == 'guest-recharge') {
                this.setState({loading:true});
                let headers = await getApiHeader();
                let params = {
                    plan_id: plan_id, 
                    mobile_number: this.props.mobileNumber, 
                    session_id : "from-guest-recharge"
                    // above session statically for checking open api guest-recharge-data-bank-balance
                }
                
                await axios.post(`${SERVER_URL}guest-recharge-data-bank-balance`, params, { headers })
                .then((response) => {
                    console.log('recharge-popup ==>', response)
                    if(response !== undefined){
                        if(response.status == 200) {
                            if(response.data !== undefined && response.data != null && response.data != ''){
                                balanceMsg = response.data.message;
                            }else{
                                balanceMsg = "";
                            }
                        }  
                    }
                    this.setState({ loading: false});
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    //console.log('error ==>', error.response)
                    balanceMsg = "";
                });
            }


                if(balanceMsg == ""){
                    swal({
                        buttons: {
                        cancel: true,
                        confirm: {text: 'Yes'},
                        },
                        text: "Would you like to proceed with this recharge for "+this.props.mobileNumber+ "?",
                        dangerMode: true,
                        })
                        .then(proceed => {
                            console.log('error ==>', proceed)
                            if(proceed)
                            {
                            
                                if(this.props.doubleDataPlan != null && this.props.doubleDataPlan != plan_id){

                                    swal({
                                        icon: "warning",
                                        buttons: {
                                            confirm: "Yes, Proceed",
                                            cancel: "No, Select the "+this.props.doubleDataPlanName
                                        },
                                        text: "You are currently on an offer plan. If you change your plan now, the offer will be cancelled.",
                                        dangerMode: true,
                                        })
                                        .then(proceed => {
                                            console.log('error ==>', proceed)
                                            if(proceed)
                                            {
                    
                                                // this.state({plan_after_confirmation: plan}, ()=>{
                    
                                                    if(this.props.from == 'guest-recharge') {
                                                      this.selectPlan(plan);
                                                      return;
                                                    }else{
                                                      this.rechargeConfirm(plan);
                                                    }
                    
                                                // });
                    
                                            }
                                    });

                                }else{

                                   
                                    if(this.props.from == 'guest-recharge') {
                                      this.selectPlan(plan,this.props.doubleDataPlan);
                                      return;
                                    }else{
                                      this.rechargeConfirm(plan);
                                    }
        
                                   
                                }
    
                            }
            
                    }); 
                }else{
                    swal({
                        icon: "warning",
                        buttons: {
                        cancel: true,
                        confirm: {text: 'Yes'},
                        },
                        text: balanceMsg + "\n Would you like to proceed with this recharge for "+this.props.mobileNumber+ "?",
                        dangerMode: true,
                        })
                        .then(proceed => {
                            console.log('error ==>', proceed)
                            if(proceed)
                            {
                                
                                if(this.props.doubleDataPlan != null && this.props.doubleDataPlan != plan_id){

                                    swal({
                                        icon: "warning",
                                        buttons: {
                                            confirm: "Yes, Proceed",
                                            cancel: "No, Select the "+this.props.doubleDataPlanName
                                        },
                                        text: "You are currently on an offer plan. If you change your plan now, the offer will be cancelled.",
                                        dangerMode: true,
                                        })
                                        .then(proceed => {
                                            console.log('error ==>', proceed)
                                            if(proceed)
                                            {
                    
                                                // this.state({plan_after_confirmation: plan}, ()=>{
                    
                                                    if(this.props.from == 'guest-recharge') {
                                                      this.selectPlan(plan);
                                                      return;
                                                    }else{
                                                      this.rechargeConfirm(plan);
                                                    }
                    
                                                // });
                    
                                            }
                                    });

                                }else{

                                    if(this.props.from == 'guest-recharge') {
                                      this.selectPlan(plan,this.props.doubleDataPlan);
                                      return;
                                    }else{
                                      this.rechargeConfirm(plan);
                                    }

                                }

    
                                
    
                            }
                    });
                }
                

            } else {
                if(this.props.mobileNumber != null){
                    if(this.props.activeStatus =='9'){
                        swal("Mobile Number "+this.props.mobileNumber+" has been disconnected.", "If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.", "warning");
                    }else{
                        swal("Mobile Number "+this.props.mobileNumber+" is not active", "If you have any queries, please feel free to contact Telsim Customer Care through chat or email at support@telsim.com.au or call us at 1300-TELSIM.", "warning");
                    }
                }
            }
            //===============new================//
        }
    }

    
    selectPlan = async (plan,dplan_id=null) => {

        // this.props.changePlan(plan_id, this.props.type); 


        let plan_id = plan.plan_id;
        // changes for recharge guest
        if(this.props.from == 'guest-recharge') {
            if(plan_id == null){
                let msg = 'Select a plan first.';
                if(plan_id === false) {
                    let msg = 'Error in select plan';
                }
                swal(msg,"error");
            }
            this.props.goNextStep(plan_id,dplan_id);
            return;
            // changes for recharge guest end
        }else{
            let selectedPlan = await this.props.changePlan(plan, this.props.type);
            if(selectedPlan === plan_id) {
                if((this.props.from) && (this.props.from === 'add-mobile-plan')) {
                    this.props.goNextStep(plan);
                } else {
                    this.props.goNextStep(plan_id);
                }
            } else {
                this.setState({loading:false});
                let msg = 'Select a plan first.';
                if(selectedPlan === false) {
                    let msg = 'Error in select plan';
                }
                swal(msg,"error");
            }
            return;
        }

    }

    render()
    {

        return(
                <>
                {(this.state.loading === true) ? <PageLoader/> : null}
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                {(this.state.plan.length > 0) ? this.state.plan.map((plan, index)=>{
                                    return (<div className={`col plan_box ${this.state.gridClass} mb10`}>
                                                <div className="position-relative " style={{width:"100%"}}>
                                                    {(plan.is_bonus === true) ?

                                                    <div className="my-auto">
                                                        <div className="offer_div">
                                                            <div className=" align-middle card card-block custom-offer">
                                                                25GB + 5GB bonus data on activation
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                    }
                                                    <div className={`card  border-danger w-100  ${plan.is_bolt != '2' ? 'h-100' : ''}  ${plan.has_offerAmount ? 'hasOffer' : ''}`}>
                                                    {(plan.popular_status === "1" && plan.is_bolt === 4) ? <div className="ribbon">5G Plan</div>: null}
                                                    {(plan.popular_status === "1" && plan.is_bolt !== 4) ? <div className="ribbon">Popular</div>: null}
                                                    {(plan.popular_status == "2" && plan.is_bolt === 0 && plan.plan_account_status ===1) ? <div className="ribbon">5G Plan</div>: null}
                                                    {/* {(plan.popular_status == "2" && plan.is_bolt === 0 && plan.plan_account_status ===0) ? <div className="ribbon">5G Trail</div>: null} */}
                                                    
                                               
                                                        <div className="card-header plan_header text-center" style={{paddingTop:"13px", paddingBottom:"13px"}}>
                                                            {/* <span className="card-subtitle mb-2 fontSize18"  >${plan.amount}</span> */}
                                                            <h4 className="card-title m0">
                                                                
                                                            
                                                            {((plan.is_bolt === 0 || plan.is_bolt === 1 || plan.is_bolt === 4 || plan.is_bolt === 5)   ) ? 
                                                                <>
                                                                {plan.total_data}
                                                                GB data
                                                                    <>
                                                                    {plan.has_offerAmount==0 || plan.has_offerAmount ==null ?
                                                                    <span className="card-subtitle mb-2 fontSize18" style={{fontWeight: 300}}> - ${plan.amount}</span>
                                                                    :
                                                                    <span className="card-subtitle mb-2 fontSize18" style={{fontWeight: 300}}> - <l style={{textDecoration: 'line-through'}}>${plan.amount}</l> <l style={{fontWeight: 600}}>${plan.has_offerAmount}</l></span>
                                                                    }
                                                                    </>
                                                                </>

                                                            : 

                                                                ((plan.is_bolt === 0 || plan.is_bolt === 1 || plan.is_bolt === 4) && (this.props.from !== 'activation') && (this.props.from !== 'add-mobile-plan')  ) ? 
                                                                        <>
                                                                        {plan.total_data}
                                                                        GB data
                                                                        <span className="card-subtitle mb-2 fontSize18" style={{fontWeight: 300}}> - ${plan.amount}</span>
                                                                        </>

                                                                    : 

                                                                        <>
                                                                        {(plan.is_bolt === 2 || plan.is_bolt === 3 ) ?
                                                                            <>
                                                                            ${plan.amount}
                                                                            </>
                                                                            : 
                                                                            <>
                                                                            {plan.data}
                                                                            GB data
                                                                            <span className="card-subtitle mb-2 fontSize18" style={{fontWeight: 300}}> - ${plan.amount}</span>
                                                                            </>
                                                                            }
                                                                        </>

                                                             }
                                                            
                                                            


                                                           
                                                            
                                                            </h4>
                                                        </div>
                                                        
                                                        
                                                        <div className="card-header text-center p-y-1">
                                                            
                                                            <h5 className="card-subtitle">
                                                                
                                                            {((plan.is_bolt === 0) || (plan.is_bolt === 3) || (plan.is_bolt === 4) || (plan.is_bolt === 5)) ? <> {plan.validity} Days Expiry </>: null}

                                                            {(plan.is_bolt === 7) ? <> {plan.offerName}</>: null}

                                                            {(plan.is_bolt === 2) ? <> {Object.keys(plan.countries).length} Destinations </>: null}

                                                           
                                                                
                                                            </h5>
                                                        </div>

                                                            {(plan.is_bolt === 7) ? 
                                                            <>
                                                              <div className="card-header text-center p-y-1 IrBlock">
                                                                <h5 className="card-subtitle">
                                                                    {Object.keys(plan.ir_countries).length} Destinations 
                                                                </h5>
                                                               </div>
                                                             </>: null}
                                                                
                                                           
                                                            
                                                        



                                                        <div className="card-body">
                                                            <ul className="feature_list">
                                                            
                                                                { (plan.is_bolt === 0) ? 
                                                                    <>
                                                                        {((plan.bonus_data > 0) && (plan.has_bonus === true) ) ? 
                                                                            <li>{plan.data}GB + {plan.bonus_data}GB bonus data on activation.</li>
                                                                        :   
                                                                                (((plan.bonus_data > 0) && (plan.has_bonus === true)) && (this.props.from !== 'activation') && (this.props.from !== 'add-mobile-plan') ) ? 
                                                                                <li>{plan.data}GB + {plan.bonus_data}GB bonus data on recharge.</li>
                                                                            :   
                                                                                <li>{plan.data}GB data.</li>
                                                                    
                                                                        }
                                                                        <li>Unlimited Calls & Text to standard numbers. All for use in Australia.</li>
                                                                        {(plan.extra_credit !== null) ? <li>${plan.extra_credit} Extras Credit for international calls, messages and selected premium SMS services.</li> : null}
                                                                        {((plan.countries) && (Object.keys(plan.countries).length > 0)) ? <li>Unlimited standard international calls & text to {Object.keys(plan.countries).length} Countries.</li> : null}
                                                                        
                                                                        {(plan.popular_status == "2" && plan.plan_account_status == "1") ? 
                                                                          <li>5G network access.</li>
                                                                        : <li>4G network access.</li>
                                                                        }

                                                                        {(plan.popular_status == "2" && plan.plan_account_status == "1") ? 
                                                                          <li>Download speeds are capped at 100 Mbps on 4G & 5G.</li>
                                                                        : ''
                                                                        }
  
                                                                        <li>Data banking upto 500GB.</li>
                                                                        { (plan.data_gifting_status === "1") ? <li>Gift & Receive Data.</li>: null }
                                                                        { (plan.data_gifting_status === "2") ? <li>Receive Data.</li>: null }
                                                                        { (plan.data_gifting_status === "3") ? <li>Gift Data.</li>: null }
                                                                    </> 
                                                               : null }

                                                                { (plan.is_bolt === 4) ? 
                                                                    <>
                                                                        {((plan.bonus_data > 0) && (plan.has_bonus === true) ) ? 
                                                                            <li>{plan.data}GB + {plan.bonus_data}GB bonus data on activation.</li>
                                                                        :   
                                                                        (((plan.bonus_data > 0) && (plan.has_bonus === true)) && (this.props.from !== 'activation') && (this.props.from !== 'add-mobile-plan')) ? 
                                                                            <li>{plan.data}GB + {plan.bonus_data}GB bonus data on recharge.</li>
                                                                        :   
                                                                            <li>{plan.data}GB data.</li>
                                                                        
                                                                        }
                                                                        <li>Unlimited Calls and Text to standard numbers in Australia.</li>
                                                                        {(plan.extra_credit !== null) ? <li>${plan.extra_credit} extras credit for international calls, messages and selected premium SMS Services.</li> : null}
                                                                        {((plan.countries) && (Object.keys(plan.countries).length > 0)) ? <li>Unlimited standard international calls & text to {Object.keys(plan.countries).length} Countries.</li> : null}
                                                                        <li>5G network access.</li>
                                                                        <li>Download speeds are capped at 250 Mbps on 4G & 5G.</li>
                                                                        <li>Data banking upto 500GB.</li>
                                                                        { (plan.data_gifting_status === "1") ? <li>Gift & Receive Data.</li>: null }
                                                                        { (plan.data_gifting_status === "2") ? <li>Receive Data.</li>: null }
                                                                        { (plan.data_gifting_status === "3") ? <li>Gift Data.</li>: null }
                                                                    </> 
                                                               : null }

                                                                { (plan.is_bolt === 5) ? 
                                                                    <>
                                                                        {((plan.bonus_data > 0) && (plan.has_bonus === true) ) ? 
                                                                            <li>{plan.data}GB + {plan.bonus_data}GB bonus data on activation.</li>
                                                                        :   
                                                                            <li>{plan.data}GB data.</li>
                                                                        }
                                                                        <li>4G network access.</li>
                                                                        <li>No standard/international call or text inclusions</li>
                                                                        { (plan.data_gifting_status === "1") ? <li>Gift & Receive Data.</li>: null }
                                                                        { (plan.data_gifting_status === "2") ? <li>Receive Data.</li>: null }
                                                                        { (plan.data_gifting_status === "3") ? <li>Gift Data.</li>: null }
                                                                    </> 
                                                               : null }

                                                                { (plan.is_bolt === 2 && plan.idd_pack_id === 3) ? 
                                                            
                                                                    <>
                                                                    
                                                                        <li>
                                                                                No international SMS included. 
                                                                        </li>
                                                                        <li>

                                                                                Includes 60 minutes international calls to selected destinations.

                                                                        </li>
                                                                        <li>
                                                                                Expiry - Inline with underlying plan.
                                                                        </li>

                                                                    </>
                                                            
                                                                : null }

                                                        { (plan.is_bolt === 2 && plan.idd_pack_id === 4) ? 
                                                            
                                                            <>
                                                            
                                                                <li>
                                                                        No international SMS included. 
                                                                </li>
                                                                <li>

                                                                        Includes 120 minutes international calls to selected destinations.

                                                                </li>
                                                                <li>
                                                                        Expiry - Inline with underlying plan.
                                                                </li>

                                                            </>
                                                    
                                                        : null }

                                                                { (plan.is_bolt === 1) ?

                                                                    <>
                                                                        <li>
                                                                                Includes 1 GB data.
                                                                        </li>

                                                                        <li>
                                                                                Expiry: Inline with underlying plan.
                                                                        </li>

                                                                        <li>
                                                                                Active recharge is required to use Data Bundle.
                                                                        </li>
                                                                    </>

                                                                : null}


                                                                { (plan.is_bolt === 7) ? 
                                                            
                                                                    <>
                                                                        <li>7-day validity </li>
                                                                        <li>Voice & Value-Added Services : 30 Mins</li>
                                                                        <li>5GB data</li>
                                                                        <li>30 international SMS to selected destinations</li>
                                                                        <li>Travel pack activates upon entering eligible destinations and uses one of the inclusions (voice calls, SMS, or data)</li>
                                                                        
                                                                    </>
                                                            
                                                                : null }

                                                                 




                                                                { (plan.is_bolt === 3) ?
                                                                    <>

                                                                        {(plan.extra_credit !== null) ? <li>${plan.extra_credit} Extras Credit for international calls, messages and selected premium SMS services.</li> : null}

                                                                    </>
                                                                : null}

                                                            </ul>
                                                        </div>

                                                    {((plan.countries) && (typeof plan.countries === 'object') && ((Object.keys(plan.countries).length) > 0)) ? 


                                                        <div className="accordian_div">
                                                            <div className="accordion customAccordian" id={`accordionExample${index}`}>
                                                                <div className="card accordianCard b0">
                                                                    <div className="card-header pxy20 accordianCardHeader" id={`headingOne${index}`} >
                                                                        <h2 className="mb-0">
                                                                            <button className="btn btn-link btn-block text-left headerCollapse pxy20" type="button" data-toggle="collapse" data-target={`#collapseOne${index}`} aria-expanded="true" aria-controls={`collapseOne${index}`}  >
                                                                            <i className="fa fa-chevron-down whatElseInclude"></i> Country list
                                                                            </button>
                                                                        </h2>
                                                                    </div>
                                                                    <div id={`collapseOne${index}`} className="collapse" aria-labelledby={`headingOne${index}`} data-parent={`#accordionExample${index}`}>
                                                                        <div className="card-body accordianCardBody">

                                                                        {Object.keys(plan.countries).map((keyName)=>{
                                                                            return (<p> <i className="fa fa-check custom-plan-expanded"></i> {plan.countries[keyName]} </p>)
                                                                        })}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    : null

                                                    }




                                                 
                                                    {((plan.ir_countries) && (typeof plan.ir_countries === 'object') && ((Object.keys(plan.ir_countries).length) > 0)) ? 


                                                            <div className="accordian_div">
                                                                <div className="accordion customAccordian" id={`accordionExample${index}`}>
                                                                    <div className="card accordianCard b0">
                                                                        <div className="card-header pxy20 accordianCardHeader" id={`headingOneIR${index}`} >
                                                                            <h2 className="mb-0">
                                                                                <button className="btn btn-link btn-block text-left headerCollapse pxy20" type="button" data-toggle="collapse" data-target={`#collapseOneIR${index}`} aria-expanded="true" aria-controls={`collapseOne${index}`}  >
                                                                                <i className="fa fa-chevron-down whatElseInclude"></i> Country list
                                                                                </button>
                                                                            </h2>
                                                                        </div>
                                                                        <div id={`collapseOneIR${index}`} className="collapse" aria-labelledby={`headingOneIR${index}`} data-parent={`#accordionExample${index}`}>
                                                                            <div className="card-body accordianCardBody">

                                                                            {Object.keys(plan.ir_countries).map((keyName)=>{
                                                                                return (<p> <i className="fa fa-check custom-plan-expanded"></i> {plan.ir_countries[keyName]} </p>)
                                                                            })}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                    : null

                                                    }

                                                   





                                                        <div className="card-footer bg-transparent text-center" style={{padding:"10px"}}>
                                                            {/* <button className="btn btn-danger" onClick={()=>this.recharge(plan.plan_id)}> */}
                                                            <div>
                                                            <button disabled={this.state.btn_disable['type'+plan.is_bolt]} className="btn btn-danger" onClick={()=>this.recharge(plan)}>
                                                                {
                                                                    (this.props.from == 'activation') ?
                                                                    (
                                                                        <>
                                                                            {/* {(sessionStorage.getItem('plan_id') == plan.plan_id) ? */}
                                                                            {(this.props.selectedPlan === plan.plan_id) ?
                                                                                <>
                                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                                                Selected
                                                                                </> : 'Select' 
                                                                            }

                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                (this.props.selectedPlan == plan.plan_id) ? 
                                                                                <>
                                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                                    Selected
                                                                                </>
                                                                                
                                                                                : 'Select' 
                                                                            }
                                                                        </>
                                                                    )

                                                                } 
                                                            </button>
                                                            </div>
                                                            <div style={{marginTop:"8px"}}>
                                                            <a target="_blank" style={{color:"red", textDecoration:"none", marginTop:"10px"}} href={process.env.REACT_APP_CRITICAL_IN_SUMMARY}>Critical information summary</a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                }): <div>No records found in this category.</div>}
                    </div>
                </>
        );
    }

}

export default withRouter(Plan);